import React from "react";

function Header() {
    return (
        <header className="header">
            <h1>Christopher Smith, PhD (ABD)</h1>
            <nav>
                <a href="#about">About</a>
                <a href="#experience">Experience</a>
                <a href="#family">Family</a>
            </nav>
        </header>
    );
}

export default Header;
