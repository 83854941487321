import React from "react";

function Family() {
    return (
        <section id="family" className="family">
            <h2>My Family</h2>
            <p>
                I’m a dedicated husband and father of three amazing kids. My family is the heart of everything I do, from Cub Scout activities with my 7-year-old son to exploring creativity with my 2-year-old and caring for our baby. We love spending time outdoors, and I enjoy encouraging their interests in building, making, and technology.
            </p>
        </section>
    );
}

export default Family;
