import React from "react";

function Experience() {
    return (
        <section id="experience" className="experience">
            <h2>Professional Experience</h2>
            <ul>
                <li>
                    <strong>Graduate Fellow & Team Lead</strong> – LTRI / Air Force Global Strike Command
                    <p>Lead a team of 5 engineers to build secure, scalable systems for military operations, focused on encryption and cybersecurity.</p>
                </li>
                <li>
                    <strong>TechX Lab Technician</strong> – General Dynamics Information Technology
                    <p>Developed and tested secure software platforms, built botnets for research, and created innovative AI-driven projects.</p>
                </li>
                <li>
                    <strong>Associate Consultant</strong> – Fenway Group
                    <p>Built web applications, integrated data solutions, and ensured secure data handling for Fortune 1000 clients.</p>
                </li>
            </ul>
        </section>
    );
}

export default Experience;
