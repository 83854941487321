import React from "react";

function About() {
    return (
        <section id="about" className="about">
            <h2>About Me</h2>
            <p>
                I'm Christopher Smith, a PhD candidate (ABD) in Cyberspace Engineering with a focus on cybersecurity. I specialize in zero-trust architecture, encryption, and secure systems.
                With 10+ years of experience in software development, I drive innovation across cloud computing, AI, and automation technologies.
                I'm passionate about creating secure, data-driven platforms and love staying ahead in the evolving cybersecurity landscape.
            </p>
        </section>
    );
}

export default About;
